/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Editor } from 'components/core/rich-editor/editor';
import { SelectDate } from 'components/tools/select_date';
import { Viewer } from 'components/core/rich-editor/viewer';
import { Dropdown } from 'components/core/dropdown';
import { Button } from 'components/core/button';
import { SlideOver } from 'components/core/slide_over';
import { CardSectionHeading } from 'components/core/typo';
import { SelectMenu } from 'components/tools/select-menu';
import { LinkedComments } from 'components/actions-plan/linkedComments';
import { ActionPlanStats } from 'components/actions-plan/stats';
import { ToggleActionCompletion, ActionsCompletionCodeProvider } from 'components/actions-plan/actionCompletion';

// Icons
import { FiEdit2, FiTrash } from 'react-icons/fi';

// API
import { getOrCreateActionsPlan, createComment, updateComment, deleteComment, updateNote, toggleCommentCompletion, toggleLinkBetweenComments, retrieveNoteCode } from 'api/notes';

// Utils
import { locale } from 'utils/locale';
const fullDayFormat = locale.format("%A, %d %B %Y");
import classNames from 'classnames';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useActionsPlan } from 'hooks/useActionsPlan';

export function ActionsPlanSlideOver({ student, open, setOpen, onUpdate }) {
    const { t } = useTranslation("common");
    return <SlideOver size='xl3' open={open} setOpen={setOpen}>
        <div className='px-5'>
            <CardSectionHeading title={t("actions-plan")} />
            <ActionsPlan student={student} onUpdate={onUpdate} />
        </div>
    </SlideOver>
}

export function ActionsPlan({ student, hideStats, onCreateAction, onDeleteAction, onToggleCompleted, bypassCompletionComment }) {
    const { ni } = useParams();
    const params = useMemo(() => ({ student: student || ni }), [student, ni]);
    const validateParams = (p) => p.student;
    const [plan, { loading, setResult: setPlan }] = useAPI(getOrCreateActionsPlan, params, { camelize: true, validateParams});

    if (loading) return <Skeleton.List numElements={1} className="space-y-3" itemClassName="h-36" />;
    return <>
        <div className='@container'>
            <div className='flex flex-col @[900px]:flex-row gap-4'>
                {
                    !hideStats && <ActionPlanStats plan={plan} />
                }
                <div className={classNames('w-full', !hideStats && 'mt-5')}>
                    <ActionsCompletionCodeProvider>
                        <ActionsList plan={plan} setPlan={setPlan} onCreateAction={onCreateAction} onDeleteAction={onDeleteAction} onToggleCompleted={onToggleCompleted} bypassCompletionComment={bypassCompletionComment} />
                    </ActionsCompletionCodeProvider>
                </div>
            </div>
        </div>
    </>;
}

function TodaySeparator() {
    const { t } = useTranslation("common");
    return <>
        <div className="flex items-center my-4 text-gray-600">
            <hr className="flex-1 border-t border-gray-300 mx-4" />
            <span className="">{t("today")}</span>
            <hr className="flex-1 border-t border-gray-300 mx-4" />
        </div>
    </>;
}

export function ActionsList({ plan, setPlan, onCreateAction, onDeleteAction, onToggleCompleted, bypassCompletionComment, loading }) {
    const { t } = useTranslation("common");
    const [, { execute: executeUpdateNote }] = useAPI(updateNote, { noteId: plan?.id }, { camelize: true, immediate: false });
    const {comments: actions} = plan || {};
    const {onUpdate} = useActionsPlan();
    const [showAddAction, setShowAddAction] = useState(false);
    const { filter, setFilter } = useActionsPlan();

    const applyFilters = (actionsList) => {
        if (!actionsList) return [];
        return actionsList.filter(action => {
            const isPast = action?.displayDate < new Date().toLocaleDateString('en-CA');
            const isCompleted = action.completed;
            if (!filter.past && isPast) return false;
            if (!filter.completed && isCompleted) return false;
            return true;
        });
    };

    const filteredActions = useMemo(() => applyFilters(actions), [actions, filter]);
    const sortedActions = useMemo(() => filteredActions.sort((a, b) => b.displayDate > a.displayDate ? 1 : -1), [filteredActions]);

    const today = new Date().setHours(0, 0, 0, 0); // Today's date at midnight
    const todaySeparatorIdx = sortedActions.findIndex((action) => {
        const actionDate = new Date(`${action.displayDate}T00:00:00`).getTime();
        return actionDate < today;
    });

    const handleNewComment = (comment) => {
        if (!plan?.comments || plan?.comments?.length === 0) executeUpdateNote({ updateAuthor: true });
        onCreateAction?.(comment);
        setPlan((prev) => ({ ...prev, comments: [...(prev?.comments || []), comment] }));
        setShowAddAction(false);
    };
    const handleDeleteComment = (comment) => {
        onDeleteAction?.(comment);
        setPlan((prev) => ({ ...prev, comments: prev?.comments?.filter(({ id }) => id !== comment?.id) }));
    };
    const handleUpdateComment = (comment) => {
        setPlan((prev) => ({ ...prev, comments: prev?.comments?.map((c) => c.id === comment.id ? comment : c) }));
    };
    useEffect(() => {
        plan && onUpdate?.(plan);
    }, [plan, onUpdate]);

    if (loading) return <Skeleton.List numElements={1} className="space-y-3" itemClassName="h-36" />;
    return <>
        {
            showAddAction && <>
                <div className='pb-4 mt-2 mb-4 border-b'>
                    <AddAction planId={plan?.id} onResult={handleNewComment} onCancel={()=>setShowAddAction(false)} />
                </div>
            </>
        }
        <div className="flex flex-col items-end justify-between gap-2 mb-2 lg:flex-row">
            <label className="text-sm text-gray-500 uppercase">{t("planned-actions")}</label>
            <div className="flex flex-col w-full gap-2 lg:flex-row lg:w-fit">
                <ActionsFilter filter={filter} setFilter={setFilter} />
                <Button color={"active"} size="sm" disabled={showAddAction} onClick={() => setShowAddAction(prev => !prev)}>
                    {t("add-action")}
                </Button>
            </div>
        </div>
        {sortedActions && sortedActions.length > 0 ?
            <div className='flex flex-col gap-2'>
                {sortedActions.map((action, idx) => {
                    return <div key={idx}>
                        {todaySeparatorIdx === idx && <TodaySeparator />}
                        <Action
                            action={action}
                            student={plan?.student}
                            onDelete={handleDeleteComment}
                            onUpdate={handleUpdateComment}
                            onToggleCompleted={onToggleCompleted}
                            bypassCompletionComment={bypassCompletionComment}
                        />
                    </div>
                })}
            </div>
            : <div className='p-3 text-sm text-gray-500 bg-gray-100 rounded-md'>{t("no-next-action")}</div>
        }
    </>
}

export function ActionsFilter({ filter, setFilter }) {
    const { t } = useTranslation("common");

    const handleUpdate = (newFilter) => {
        setFilter(newFilter);
    };

    const values = [
        {
            name: t("actions-filters.all"),
            value: { past: true, completed: true },
        },
        {
            name: t("actions-filters.incomplete"),
            value: { past: true, completed: false },
        },
        {
            name: t("actions-filters.upcoming"),
            value: { past: false, completed: false },
        }
    ];

    const findDefault = values.find(
        v => v.value.past === filter.past && v.value.completed === filter.completed
    );

    return (
        <SelectMenu
            buttonClassName="!min-w-[10rem]"
            values={values}
            color="gray"
            value={findDefault || values[0]} // Default to "All"
            setValue={(v) => handleUpdate(v.value)}
            indexKey="name"
        />
    );
}

const actionStyles = {
    default: "border-l-gray-100 bg-gray-100",
    completed: "border-l-emerald-500",
    new: "border-l-purple-500",
    late: "border-l-red-500",
    highlighted: "border-orange-500 !border-2",
}
export function Action({ action, student, onUpdate, onDelete, onToggleCompleted, bypassCompletionComment }) {
    const { t } = useTranslation("common")
    const { displayDate, content: defaultContent, id, createdAt } = action || {};
    const formattedDate = fullDayFormat(new Date(`${displayDate}T00:00:00`));
    const [modify, setModify] = useState(false);
    const [content, setContent] = useState(defaultContent);
    const [date, setDate] = useState(new Date(`${displayDate}T00:00:00`));
    const isEmpty = !content || content?.replace(/<[^>]*>?/gm, '').trim() === ''
    const location = useLocation();
    const hash = location.hash;
    const hashValue = hash ? hash.substring(1) : null;
    const { highlight } = useActionsPlan();
    const isHighlighted = highlight === action?.id || hashValue === id;
    const linkedComments = action?.linkedComments || [];
    const isNew = new Date() - new Date(createdAt) < 1000 * 60 * 60 * 24; // Less than 24 hours
    const isPast = new Date(`${displayDate}T00:00:00`).toLocaleDateString() < new Date().toLocaleDateString('en-CA');
    const isLate = isPast && !action?.completed;
    const actionStyle = isHighlighted? actionStyles.highlighted : action?.completed ? actionStyles.completed : isLate ? actionStyles.late : isNew ? actionStyles.new : actionStyles.default;
    
    // SAVE
    const handleSave = () => {
        setModify(false);
        onUpdate?.({ ...action, content, displayDate: date.toLocaleDateString('en-CA') });
    }
    const [, { loading: saving, execute: save }] = useAPI(updateComment, { id: action?.id, comment: content, displayDate: date }, { camelize: true, immediate: false, onResult: handleSave });

    // DELETE
    const handleDelete = () => {
        setModify(false);
        onDelete?.(action);
    }
    const [, { loading: deleting, execute: deleteAction }] = useAPI(deleteComment, { id: action?.id }, { camelize: true, immediate: false, onResult: handleDelete });

    // CANCEL
    const handleCancel = () => {
        setModify(false);
        setContent(defaultContent);
        setDate(new Date(`${displayDate}T00:00:00`));
    }

    // Dissociate linked comment
    const handleDissociate = (commentId) => {
        onUpdate?.({ ...action, linkedComments: action?.linkedComments?.filter(({ id }) => id?.toString() !== commentId) });
    }

    useEffect(() => {
        if (isHighlighted) {
            const element = document.getElementById(id);
            element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [isHighlighted, id]);


    return <>
        <div id={id} className="group">
            <div className={classNames('p-2 px-2.5 rounded-md border-l-[3px] bg-gray-100 transition-all', actionStyle)}>
                <div className='flex justify-between'>
                    {!modify ? <div className="">
                        {
                            isNew && <div className='text-xs text-purple-500'>{t("new")}</div>
                        }
                        <label className='text-gray-500 text-sm mb-0.5 block'>{formattedDate}</label>
                    </div>
                        :
                        <div className='mb-1'>
                            <SelectDate orientation="left" label={t("due-date")} value={date} setValue={setDate} />
                        </div>
                    }
                    <div className="flex items-start gap-2">
                        {
                            !modify && <div className='opacity-0 group-hover:opacity-100 transition-opacity -mt-0.5'>
                                <Button color="default" size="smToolbarIcon" className="!py-0.5" onClick={() => setModify(true)}>
                                    <FiEdit2 className='mr-1 size-3' /><span className='text-sm'>{t("edit")}</span>
                                </Button>
                            </div>
                        }
                        <ToggleActionCompletion action={action} student={student} setAction={onUpdate} onToggle={onToggleCompleted} bypassCompletionComment={bypassCompletionComment} />
                    </div>
                </div>
                {
                    modify ? <>
                        <Editor id={action?.id} toolbarStyles='!bg-gray-200' editorStyles='border-2 rounded-b-md' text={content} setText={setContent} />
                        <div className='flex items-center justify-between'>
                            <Dropdown
                                onlyIcon={<div className='flex items-center py-1'>
                                    <FiTrash className='text-red-500 size-3 shrink-0' />
                                    <span className='block ml-1 text-xs leading-none text-red-500'>{t("delete")}</span>
                                </div>
                                }
                                color={"danger"}
                                menuItemsClassName="text-xs"
                                orientation="left"
                            >
                                <Dropdown.Item
                                    icon={<FiTrash className='shrink-0' />}
                                    name={t("confirm-delete")}
                                    color="danger"
                                    className="!w-60"
                                    onClick={deleteAction}
                                />
                            </Dropdown>
                            <div className='flex gap-1 mt-2'>
                                <Button color="default" size="xs" onClick={handleCancel}>
                                    {t("cancel-short")}
                                </Button>
                                <Button color="active" size="xs" loading={saving || deleting} disabled={isEmpty || saving || deleting} onClick={save}>
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </>
                        :
                        <>
                            <Viewer className='whitespace-pre-wrap' html={defaultContent} />
                            {
                                linkedComments?.length > 0 && <div className='border-t py-0.5 mt-1'>
                                    <LinkedComments student={student} parentComment={action} linkedComments={linkedComments} onUnlink={handleDissociate} />
                                </div>
                            }
                        </>
                }
            </div>
        </div>
    </>
}

export function AddAction({ planId, onResult, onCancel }) {
    const { t } = useTranslation("common")
    const defaultAction = {
        noteId: planId,
        commentDisplayDate: new Date(),
        content: "",
    };
    const [action, setAction] = useState(defaultAction);
    const isEmpty = !action?.content || action?.content?.replace(/<[^>]*>?/gm, '').trim() === ''
    const reset = () => setAction(defaultAction);
    const handleResult = ({ comments }) => {
        const latestComment = comments.reduce((acc, curr) => 
            Number(acc.id) > Number(curr.id) ? acc : curr
        , comments[0]);
        onResult?.(latestComment);
        reset();
    };
    
    const [, { loading, execute }] = useAPI(createComment, action, { camelize: true, immediate: false, onResult: handleResult });
    const handleSubmit = () => {
        execute();
    }
    return <>
        <div className=''>
            <Editor
                toolbarStyles='p-2 mb-0.5'
                editorStyles="text-black"
                minHeight={"100px"}
                id={"add-action"}
                text={action?.content}
                setText={(v) => setAction({ ...action, content: v })}
                hideToolbarToggle
            />
            <div className='flex flex-col justify-between gap-1 mt-1 lg:flex-row'>
                <div>
                    <SelectDate orientation="left" label={t("due-date")} value={action.commentDisplayDate} setValue={(v) => setAction({ ...action, commentDisplayDate: v })} />
                </div>

                <div className='flex flex-col gap-2 lg:flex-row'>
                    {
                        onCancel && <Button color={"default"} size="sm" onClick={onCancel}>
                            {t("cancel-short")}
                        </Button>
                    }
                    <Button loading={loading} disabled={loading || isEmpty} color="active" size="sm" onClick={handleSubmit}>{t("submit-action")}</Button>
                </div>
            </div>
        </div>
    </>
}