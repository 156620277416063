import React from 'react';

// Components
import { SelectSchoolYear } from 'components/tools/school_year_list';
import { SelectSchool } from 'components/tools/schools_list';
import { SearchTeams } from 'components/tools/search_teams';
import { SearchStudents } from 'components/tools/student_search';
import { SearchStudyCode } from 'components/tools/select_study_code';
import { SelectActivePairing } from 'components/tools/select_active_pairing';
import { SelectAvailableForPairing } from 'components/tools/select_available_for_pairing';
import { SelectPaired } from 'components/tools/select_paired';
import { SelectLeftService } from 'components/tools/select_left_service';
import { SelectPreset } from 'components/tools/select_preset';
import { SearchPreset } from 'components/tools/search_preset';
import { SelectEventType } from 'components/tools/select_event_type';
import { SelectDate } from 'components/tools/select_date';
import { SelectPeriods } from 'components/tools/select_period';
import { SearchUser } from 'components/tools/search_user';

// Hooks
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import classnames from 'classnames';

// Utils
import { isString } from 'utils/var_check';


// Context
import { SearchContext } from 'contexts/search';
import { FiClock } from 'react-icons/fi';
import { SelectHasBeenMet } from './select_has_been_met';
import { SelectConfirmedPairings } from './select_confirmed_pairings';
import { SearchCoach } from './search_coach';

export function FilterBar({school, 
                          schoolYear, 
                          student, 
                          team, 
                          studyCode, 
                          eventType,
                          pairingActive, 
                          availableForPairing, 
                          leftService, 
                          schoolFailedPeriods,
                          searchPreset,  
                          hasBeenMet,
                          paired,
                          preset,
                          coordo,
                          coach,
                          activeSinceFrom,
                          activeSinceTo,
                          confirmedPairings,
                          children,
                          period,
                          disabledPeriod,
                          noMargin,
                          color="white",
                          Context=SearchContext,
                          limitToUser=true,
                          presetTo}){
  const {t} = useTranslation("common");
  const { filters, dispatchFilters } = useContext(Context);
  const teamExtraParams = useMemo(()=>({preset: filters?.preset, school: filters?.school, period: filters?.period}), [filters && filters?.preset, filters && filters?.period, filters && filters?.school])
  const teamInfo = useMemo(()=>{
    if (!filters) return null;

    const info = [];
    if (filters?.period){
      info.push(isString(filters?.period)? filters?.period: filters?.period.slug)
    }
    if (filters?.school){
      info.push(isString(filters?.school)? filters?.school: filters?.school.name)
    }
    if (filters?.preset){
      info.push(isString(filters?.preset)? filters?.preset: filters?.preset.name)
    }
    if (info.length===0){
      return null;
    }
    return t("filters-activated.info", {info:info.join(", ")});

  }, [filters && filters?.preset, filters && filters?.period, filters && filters?.school])
  const studentExtraParams = useMemo(()=>({team: filters?.team, preset: filters?.preset}), [filters && filters?.preset, filters && filters?.team])
  return <div className={classnames("flex items-center h-12 space-x-3", !noMargin && "px-4")}>
            {children}
            <div className="flex-1"/>
            {coordo&&<SearchUser color={color} value={filters?.coordo} setValue={(value)=>dispatchFilters?.({type: "coordo", value})}/>}
              {coach&&<SearchCoach color={color} value={filters?.coach} setValue={(value)=>dispatchFilters?.({type: "coach", value})}/>}
            {schoolFailedPeriods && <SelectDate showLabelInDropdown onlyIcon={<><FiClock className='inline mr-2'/><span className="inline text-sm">EO</span></>} label={t("select-failed-open-after")} value={filters?.failedClassesOpenAfter} setValue={(value)=>dispatchFilters?.({type: "failedClassesOpenAfter", value})}/>}
            {schoolFailedPeriods && <SelectDate showLabelInDropdown onlyIcon={<><FiClock className='inline mr-2'/><span className="inline text-sm">EF</span></>} label={t("select-failed-closed-before")} value={filters?.failedClassesClosedBefore} setValue={(value)=>dispatchFilters?.({type: "failedClassesClosedBefore", value})}/>}
            {pairingActive&&<SelectActivePairing color={color} value={filters?.active} setValue={(value)=>dispatchFilters?.({type: "active", value})}/>}
            {studyCode && <SearchStudyCode color={color} value={filters?.studyCode} setValue={(value)=>dispatchFilters?.({type: "studyCode", value})}/>}
            {student&&<SearchStudents color={color} extraParams={studentExtraParams} value={filters?.student} setValue={(value)=>dispatchFilters?.({type: "student", value})}/>}
            {team&&<SearchTeams info={teamInfo} color={color} value={filters?.team} extraParams={teamExtraParams} setValue={(value)=>dispatchFilters?.({type: "team", value})}/>}
            {schoolYear&&<SelectSchoolYear color={color} value={filters?.schoolYear} setValue={(value)=>dispatchFilters?.({type: "schoolYear", value})}/>}
            {school&&<SelectSchool color={color} value={filters?.school} setValue={(value)=>dispatchFilters?.({type: "school", value})}/>}
            {eventType&&<SelectEventType color={color} value={filters?.eventType} setValue={(value)=>dispatchFilters?.({type: "eventType", value})}/>}
            {availableForPairing&&<SelectAvailableForPairing color={color} value={filters?.availableForPairing || null} setValue={(value)=>dispatchFilters?.({type: "availableForPairing", value})}/>}
            {paired&&<SelectPaired color={color} value={filters?.paired || null} setValue={(value)=>dispatchFilters?.({type: "paired", value})}/>}
            {leftService&&<SelectLeftService color={color} value={filters?.leftService} setValue={(value)=>dispatchFilters?.({type: "leftService", value})}/>}
            {hasBeenMet&&<SelectHasBeenMet period={filters?.period} color={color} value={filters?.hasBeenMet} setValue={(value)=>dispatchFilters?.({type: "hasBeenMet", value})}/>}
            {confirmedPairings&&<SelectConfirmedPairings color={color} value={filters?.confirmed} setValue={(value)=>dispatchFilters?.({type: "confirmed", value})}/>}
            {preset&&<SelectPreset color={color} value={filters?.preset} limitToUser={limitToUser} setValue={(value)=>dispatchFilters?.({type: "preset", value})}/>}
            {searchPreset&&<SearchPreset to={presetTo} color={color} value={filters?.preset} limitToUser={limitToUser} setValue={(value)=>dispatchFilters?.({type: "preset", value})}/>}
            {activeSinceFrom&&<SelectDate  label={"Actif depuis"} color={color} value={filters?.activeSinceFrom || null} setValue={(value)=>dispatchFilters?.({type: "activeSinceFrom", value})}/>}
            {activeSinceTo&&<SelectDate label={"Actif avant"} color={color} value={filters?.activeSinceTo || null} setValue={(value)=>dispatchFilters?.({type: "activeSinceTo", value})}/>}
            {period&&<SelectPeriods disabled={disabledPeriod} color={color} value={filters?.period || null} setValue={(value)=>dispatchFilters?.({type: "period", value})}/>}
            
          </div>
}