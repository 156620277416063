import React, { useReducer, useMemo, createContext, useEffect } from "react";
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useLocation } from 'react-router-dom';
import { getFullCurrentPeriod } from 'utils/period';

function filterReducer(state, action){
  switch (action.type){
    case 'search':
      // action-> {type:search, value: 'john'}
      return {...state, search: action.value};
    case 'schoolYear':
      return {...state, schoolYear: action.value};
    case 'school':
      return {...state, school: action.value};
    case 'student':
      return {...state, student: action.value};
    case 'paired':
        return {...state, paired: action.value};
    case 'availableForPairing':
      return {...state, availableForPairing: action.value};
    case 'leftService':
      return {...state, leftService: action.value};
    case 'team':
      return {...state, team: action.value};
    case 'tutor':
      return {...state, tutor: action.value};
    case 'pairing':
      return {...state, pairing: action.value};
    case 'date':
      return {...state, date: action.value};
    case 'sport':
        return {...state, sport: action.value};
    case 'dates':
      return {...state, ...action.value};
    case 'fromDate':
      return {...state, fromDate: action.value};
    case 'toDate':
      return {...state, toDate: action.value};
    case 'includeNotes':
      return {...state, includeNotes: action.value};
    case 'period':
      return {...state, period: action.value};
    case 'active':
      return {...state, active: action.value};
    case 'coach':
      return {...state, coach: action.value};
    case 'preset':
      return {...state, preset: action.value};
    case 'hasBeenMet':
      return {...state, hasBeenMet: action.value};
    case 'studyCode':
      return {...state, studyCode: action.value};
    case 'activeSinceFrom':
      return {...state, activeSinceFrom: action.value};
    case 'activeSinceTo':
      return {...state, activeSinceTo: action.value};
    case 'dataType':
      return {...state, dataType: action.value};
    case 'failedClassesClosedBefore':
      return {...state, failedClassesClosedBefore: action.value};
    case 'failedClassesOpenAfter':
      return {...state, failedClassesOpenAfter: action.value};
    case 'assigned':
        return {...state, assigned: action.value};
    case 'confirmed':
        return {...state, confirmed: action.value};
    case 'assignedTo':
        return {...state, assignedTo: action.value};
    case 'ordering':
      if (state.orderBy===action.value){
        if (state.orderDirection==='desc'){
          return {...state, orderBy: action.value, orderDirection: 'asc'};
        }
        return {...state, orderBy: null, orderDirection: null};
      }
      return {...state, orderBy: action.value, orderDirection: 'desc'};
    case 'reset':
      return {};
    case 'set':
        return {...action.value};
    default:
      return state
  }
}

function updateWithDefaultFields(val, defaultFields){
  if (!defaultFields) return val;
  return {...val, ...defaultFields};
}
function setAutoPeriod(val, autoPeriod){
  if (!autoPeriod) return val;
  return {...val, period: getFullCurrentPeriod()};
}

const SearchContext = createContext({});

const SearchContextProvider = ({ children, doNotStore, storageKey, defaultState, defaultFields, autoPeriod }) => {
  const location = useLocation();
  const [cachedFilters, setCachedFilters] = useLocalStorage(storageKey || (location.pathname+'_filters'));

  const parsedDefaultFilters = useMemo(()=>{
    if (!cachedFilters || doNotStore) return;
    if (cachedFilters.fromDate) cachedFilters.fromDate = new Date(cachedFilters.fromDate);
    if (cachedFilters.toDate) cachedFilters.toDate = new Date(cachedFilters.toDate);
    if (cachedFilters.date) cachedFilters.date = new Date(cachedFilters.date);
    if (cachedFilters.activeSinceFrom) cachedFilters.activeSinceFrom = new Date(cachedFilters.activeSinceFrom);
    if (cachedFilters.activeSinceTo) cachedFilters.activeSinceTo = new Date(cachedFilters.activeSinceTo);
    if (cachedFilters.failedClassesClosedBefore) cachedFilters.failedClassesClosedBefore = new Date(cachedFilters.failedClassesClosedBefore);
    if (cachedFilters.failedClassesOpenAfter) cachedFilters.failedClassesOpenAfter = new Date(cachedFilters.failedClassesOpenAfter);
    return cachedFilters;
  }, [])
  const [filters, dispatchFilters] = useReducer(filterReducer, 
                        setAutoPeriod(updateWithDefaultFields(parsedDefaultFilters || defaultState || {}, defaultFields), autoPeriod));
  
  useEffect(()=>{
    if (!doNotStore) setCachedFilters(filters);
  }, [filters]);
  
  useEffect(()=>{
    if (!doNotStore) setCachedFilters();
  }, []);

  return (
    <SearchContext.Provider value={{ filters, dispatchFilters }}>
      {children}
    </SearchContext.Provider>
  );
};

export { SearchContext, SearchContextProvider };