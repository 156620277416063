import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// Components
import { Button } from 'components/core/button';
import { CheckBox } from 'components/tools/checkbox';
import { FlexNav } from 'components/core/layouts/flex';
import { InfoSectionHeading  } from 'components/core/typo';
import { SearchStudents } from 'components/tools/student_search';
import { SelectRecentStudySession } from 'components/study-sessions/select_recent';
import { SelectStudyCode } from 'components/tools/select_study_code';
import { SelectDateUncollapsed } from 'components/tools/select_date';
import { SelectSchoolSubject } from 'components/tools/select_school_subjects';
import { SelectSessionLength } from 'components/tools/select_length';
import { CheckShortSessionsNotes } from 'components/study-sessions/check_short_sessions';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { Options } from 'components/study-sessions/options';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Skeleton } from 'components/core/skeleton';
import { FiAlertTriangle } from 'react-icons/fi';
import { TeamMemberListWithStudySessions } from 'components/study-sessions/team_list';
import { PairingCell } from 'components/pairings/list';
import { Fragment } from 'react';
import { StudySessionCell } from 'components/study-sessions/cell';
// API
import { retrieveStudentActivePairing } from 'api/students';
import { createStudySession} from 'api/study_sessions';

import { locale } from 'utils/locale';
const fullDayFormat = locale.format("Le %A, %d %B %Y");

function StudySessionDidNotOccured({value, setValue}){
  const { t } = useTranslation("common");
  return <div className="bg-gray-100 py-1 px-3 rounded-lg">
            <CheckBox  orientation="left" name={t("study-session-has-not-occured")} value={value} setValue={setValue}/>
        </div>
}

function StudySessionDoesCount({value, setValue}){
  const { t } = useTranslation("common");
  return <div className="bg-gray-100 py-1 px-3 rounded-lg text-gray-500">
            <CheckBox orientation="left" name={t(value?"study-session-does-count": "study-session-does-not-count")} value={value} setValue={setValue}/>
        </div>
}

function StudySessionIsRetake({isRetake, setIsRetake, date, value, setValue, student}){
  const { t } = useTranslation("common");
  return <div className={classnames("py-1 mt-3 px-3 rounded-lg", isRetake && !value? "bg-yellow-50 border-2 border-yellow-300": "bg-gray-100")}>
            <CheckBox  orientation="left" name={t("study-session-is-a-retake")} value={isRetake} setValue={setIsRetake}/>
            {isRetake && <SelectRecentStudySession date={date} value={value} setValue={setValue} student={student} studyCode={"tutorat"} sessionOccurred={false}/>}
        </div>
}


function SelectPairing({ pairings, value, setValue }){


  return <div className="grid grid-cols-1 gap-3 md:gap-3">
            {pairings.map(d=>
              <div key={d.id} 
                className={classnames("rounded-md bg-gray-100 col-span-1 p-2 py-2.5 flex items-center cursor-pointer border-0",
                  value && value.id===d.id? "text-white": value ? "opacity-50": " border-transparent")}
                 onClick={()=>setValue(d)}>
                <div className="w-6 text-center" >
                  <input type="radio" id={d.id} name={"pairing"} checked={(value && value.id===d.id) || false} />
                </div>
              <div className="flex-1 ml-3">
                <PairingCell expanded {...d}/>
              </div>
            </div>)}
         </div>

}


function AdditionalTutoringSession({student, activePairings, pairing, setPairing, loading}){
    // Fetch the pairing
  const { t } = useTranslation("common");

  useEffect(()=>{
    if (activePairings && activePairings.length===1) setPairing(activePairings[0]);
  }, [activePairings])

  return  <div>
            <InfoSectionHeading 
                subtitle={activePairings && activePairings.length>1 ? t("study-session.pairing-title-multiple") :t("study-session.pairing-title")} 
                description={activePairings && activePairings.length>1? t("study-session.pairing-description-multiple") :t("study-session.pairing-settings-description")} />
            <div>
              {loading? 
                <Skeleton className="h-12"/>:
                !activePairings || activePairings.length==0?
                  <EmptyState Icon={FiAlertTriangle} 
                  color={"warning"}
                  title={t("study-session.no-pairing-title", {student: student && student.name})}
                  description={t("study-session.no-pairing-description", {student: student && student.name})}/>:
                  <SelectPairing value={pairing} setValue={setPairing} pairings={activePairings}/>
              }
            </div>
          </div>
}

/*
  Side navigation is just a view to collapse the results
*/
function SideNavigation({student, 
                            date, 
                            subjects, 
                            pairing, 
                            activePairings,
                            sessionLength, 
                            studyCode, 
                            options, 
                            studySessionRetaken,
                            isRetake,
                            sessionFormat, 
                            studySessionHasNotOccured, 
                            studySessionDoesCount,
                            setStudySessionDoesCount,
                            onSuccess}){
  const { t } = useTranslation("common");
  const [disabledButton, setDisabledButton] = useState(false);

  const validOptions = useMemo(()=>Object.values(options).filter(d=>!d.disabled &&
     (studyCode && studyCode.code===d.study_code) 
     && (studyCode && studyCode.code==="tutorat" && studySessionHasNotOccured? d.use_if_study_session_has_not_occured: !d.use_if_study_session_has_not_occured))
  , [studyCode, options, studySessionHasNotOccured]);

  const steps = useMemo(()=>{
    const studentStep = {
      name: t("student"), 
      description: student && student.name,
      completed: student
    };
    const codeStep =  {
      name: t("study-code"), 
      description: studyCode&& (studyCode.code==="tutorat"?pairing&& t("selected-study-code", {code: studyCode && studyCode.name, tutor: pairing && pairing.tutor.name}): studyCode && studyCode.name), 
      completed: studyCode, 
      warning: student && studyCode && studyCode.code==="tutorat" && !pairing && (activePairings? t("study-session.no-pairing-selected-description", {student: student && student.name}) :t("study-session.no-pairing-description", {student: student && student.name}))
    }
    const didNotOccurStep = {
      name: t("study-session-has-not-occured"), 
      description: validOptions && validOptions.length>0? null: t("study-session-has-not-occured-description"),
      completed: validOptions && validOptions.length>0
    }
    const studySessionRetakenStep = {
      name: t("study-session-is-retake"), 
      description: studySessionRetaken && studySessionRetaken.id? studySessionRetaken.period: t("you-must-provide-retaken-session"),
      completed: studySessionRetaken && studySessionRetaken.id
    }
    const dateStep = {
      name: t("date"), 
      description: date && fullDayFormat(date), 
      completed: date
    }
    const lengthStep = {
      name: t("study-session.session-length"), 
      description: sessionLength && `${sessionLength} min.`, 
      completed: sessionLength
    }
    const sessionFormatStep = {
      name: t("study-session.session-format"), 
      description: sessionFormat && `${sessionFormat.name}`, 
      completed: sessionFormat
    }
    const subjectsStep = {
      name: t("school-subjects"), 
      description: subjects && subjects.map(d=>d.name).join(', '), 
      completed: subjects && subjects.length>0
    }

    if (!studyCode || studyCode.code !== "tutorat"){
      return [studentStep, codeStep, dateStep, lengthStep, subjectsStep];
    }
    if (studySessionHasNotOccured){
      return [studentStep, codeStep, dateStep, didNotOccurStep];
    }
    if (isRetake){
      return [studentStep, codeStep, studySessionRetakenStep, dateStep, lengthStep, subjectsStep, sessionFormatStep];
    }
    return [studentStep, codeStep, dateStep, lengthStep, subjectsStep, sessionFormatStep];

  }, [isRetake, studySessionRetaken, student, studyCode, date, sessionLength, subjects, sessionFormat, studySessionHasNotOccured, validOptions, pairing]);
 
  const studySessionParams = useMemo(()=>(
    {student: student && student.ni, 
    period: date,
    lengthInMinutes: sessionLength,
    studySessionRetaken: isRetake? (studySessionRetaken && studySessionRetaken.id): null,
    pairing: pairing && pairing.id,
    code: studyCode && studyCode.code,
    options: validOptions,
    sessionFormat: sessionFormat &&sessionFormat.code,
    studySessionHasNotOccured,
    useInCount: studySessionDoesCount,
    subjects: subjects && subjects.map(d=>d.slug)}), 
  [student, isRetake, studySessionRetaken, studySessionDoesCount, pairing, date, sessionFormat, sessionLength, studyCode, subjects, validOptions, studySessionHasNotOccured])

  const [studySession, studySessionRequest] = useAPI(createStudySession, studySessionParams, {immediate: false});
  useEffect(()=>{
    if (studySession){
      onSuccess(studySession);
      setDisabledButton(true);
    }
  }, [studySession])

  const disabled = useMemo(()=>{
    setDisabledButton(false);
    if (!student) return true;
    if (!studyCode) return true;
    if (studyCode.code==="tutorat"){
      if (!pairing) return true;
      if (studySessionHasNotOccured){
        if (!date) return true;
        if (validOptions.length===0) return true;
        return false;
      }
      if (isRetake && !studySessionRetaken) return true;
      if (!date || !subjects || subjects.length===0 || !sessionLength || !sessionFormat){
        return true;
      }
      return false;
    }
    else {
      if (!date || !subjects || subjects.length===0 || !sessionLength){
        return true;
      }
      return false;
    }
  }, [student, isRetake, studySessionRetaken, date, subjects, sessionLength, sessionFormat, studyCode, studySessionHasNotOccured, validOptions, pairing])

  return (<div className="bg-gray-100 lg:bg-white rounded-lg p-3 lg:pl-6 lg:pt-8 ">
                    <InfoSectionHeading subtitle={t("summary")}/>
            {steps.filter(d=>!d.hide).map((d=>
                <div key={d.name}>
                  <div className={classnames("flex items-start py-2", d.warning&&"bg-yellow-50 rounded-lg")}>
                    {d.warning?
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-yellow-200" />
                        <span className="relative block w-2 h-2 bg-yellow-600 rounded-full" />
                      </span>:
                      !d.completed?
                      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <div className="h-2 w-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                      </div>:
                      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
                        <span className="absolute h-4 w-4 rounded-full bg-green-200" />
                        <span className="relative block w-2 h-2 bg-green-600 rounded-full" />
                      </span>
                    }
                    <div>
                      <p className={classnames("ml-3 font-medium", d.warning?"text-yellow-800":d.completed? "text-green-600": "text-gray-400")}>{d.name}</p>
                      {
                        d.warning? <p className="ml-3 text-yellow-600 ">{d.warning}</p>:
                        d.description && <p className="ml-3 text-gray-800 ">{d.description}</p>
                      }
                    </div>
                  </div>
                </div>
            ))}
          {/*Show if it counts*/}
            {!studySessionHasNotOccured && <StudySessionDoesCount value={studySessionDoesCount} setValue={setStudySessionDoesCount}/>}
            {/*Show the options*/}
            <div className="space-y-3 mt-2">
            {validOptions && 
                  validOptions.map(e=>
                    <div className={classnames("p-3 rounded-lg", !e.comment && e.requires_comment? "bg-yellow-50": "bg-white shadow lg:shadow-none lg:bg-gray-100")} key={e.slug}>
                    <p className={classnames(!e.comment && e.requires_comment?"text-yellow-800":"text-gray-800", "font-medium")}>{e.name}</p>
                    <p className="line-clamp-2 text-sm text-gray-500">{e.comment || (e.requires_comment? <span className="text-yellow-600">{t("comment-required")}</span>: t("no-comment"))}</p>
                    {e.keepOpen && <p className="text-sm text-yellow-600">{t("keep-open")}</p>}
                    </div>)
              }
            </div>

            <Button className="mt-6" 
                      color="active" 
                      onClick={studySessionRequest.execute} 
                      loading={studySessionRequest.loading} 
                      disabled={disabled || disabledButton} 
                      block 
                      size="lg">{t('create-study-session')}</Button>
            {studySessionRequest.error && <Error.Text {...studySessionRequest.error}/>}
          </div>)
}

function AfterCreationView({reset, studySessions}){
  const { t } = useTranslation("common");
  return <div className="mx-auto w-full max-w-lg space-y-8">
            <h1 className="font-semibold text-xl">{t("study-session-added-title")}</h1>
            <div className="space-y-3">
              {studySessions.map(d=><StudySessionCell key={d.id} {...d}/>)}
            </div>
            <Button block color="active" size="lg" onClick={reset}>
              {t("add-more-study-session")}
            </Button>
          </div>
}

function CreationView({student, 
                      setStudent, 
                      team, 
                      studyCode, 
                      setStudyCode, 
                      pairing, 
                      setPairing,
                      activePairings,
                      pairingRequest, 
                      studySessionHasNotOccured, 
                      setStudySessionDidNotOccured, 
                      setStudySessionDoesCount,
                      date, 
                      setDate,
                      studySessionRetaken,
                      setStudySessionRetaken,
                      sessionLength,
                      setSessionLength,
                      isRetake,
                      setIsRetake,
                      subjects,
                      setSubjects,
                      sessionFormat, 
                      setSessionFormat, 
                      options, 
                      setOptions}){
  const { t } = useTranslation("common");
  const [numTooShortStudySessionsWarnings, setNumTooShortStudySessionsWarnings] = useState();
  const extraParams = useMemo(()=>({team: team && team.slug, leftService: false}), [team])
  return <div className="w-full space-y-8 lg:pr-12 mx-auto"> 
          <InfoSectionHeading title={t("create-study-session")}/>

          {/*Student*/}
          <div>
            <InfoSectionHeading subtitle={t("student")}/>
            <SearchStudents value={student} setValue={setStudent} extraParams={extraParams} size="md"/>
            {student && student.study_session_verification_flag && 
             <EmptyState 
                  color={"warning"}
                  className="mt-6"
                  title={t("student-has-a-verification-flag-raised", {student: student && student.name})}
                  description={t("student-has-a-verification-flag-raised-description", {date: locale.format("%d %B")(new Date(student.study_session_verification_flag)), student: student && student.name})}>
              </EmptyState>}
          </div>

          {/*Study type*/}
          <div>
            <InfoSectionHeading subtitle={t("select-study-code")}/>
            <SelectStudyCode value={studyCode} setValue={setStudyCode}/>
          </div>

          {/* Extra params for tutoring */}
          {
            studyCode && studyCode.code==="tutorat" && student &&
            <AdditionalTutoringSession student={student} activePairings={activePairings} pairing={pairing} setPairing={setPairing} {...pairingRequest}/>
          }

          {/* Select the date */}
          {studyCode && student && <div>
            <InfoSectionHeading subtitle={t("select-study-session-date")}/>

            <div className="flex justify-center my-2">
              <SelectDateUncollapsed value={date} setValue={setDate}/>
            </div>
            {studyCode && studyCode.code==="tutorat" && <StudySessionDidNotOccured value={studySessionHasNotOccured} setValue={setStudySessionDidNotOccured}/>}
            {studyCode && studyCode.code==="tutorat" && !studySessionHasNotOccured && 
                  <StudySessionIsRetake isRetake={isRetake} 
                                        setIsRetake={setIsRetake} 
                                        date={date}
                                        value={studySessionRetaken} 
                                        setValue={setStudySessionRetaken} 
                                        student={student}/>}

          </div>}

          {/* Select the length */}
          {studyCode && !(studyCode.code==="tutorat" && studySessionHasNotOccured) && student && <div>
            <InfoSectionHeading subtitle={t("study-session.select-length")}/>
            <SelectSessionLength value={sessionLength} setValue={setSessionLength}/>
            {sessionLength && !studySessionHasNotOccured && studyCode.min_length_in_minutes>sessionLength && 
             <Fragment>
               <EmptyState 
                    color={"warning"}
                    className="mt-6"
                    title={t("session-is-short")}
                    description={t("session-is-short-flag-raised-description", {minimum: studyCode.min_length_in_minutes+10})}>
                </EmptyState>
                <CheckShortSessionsNotes student={student} setValue={setNumTooShortStudySessionsWarnings}/>
              </Fragment>}
            {sessionLength && !studySessionHasNotOccured && studyCode.min_length_in_minutes<=sessionLength && studyCode.min_length_in_minutes+10>sessionLength && 
             <EmptyState 
                  color={"warning"}
                  className="mt-6"
                  title={t("session-is-short-but-ok")}
                  description={t("session-is-short-but-ok-flag-raised-description", {minimum: studyCode.min_length_in_minutes+10})}>
              </EmptyState>}

          </div>}

          {/* Select the subjects */}
         {studyCode && !(studyCode.code==="tutorat" && studySessionHasNotOccured) &&  student && <div>
            <InfoSectionHeading subtitle={t("select-subjects")}/>
            <SelectSchoolSubject value={subjects} setValue={setSubjects} student={student} allowMultiple={true} setStudySessionDoesCount={setStudySessionDoesCount}/>
          </div>}

        {/* Select the format */}
         {studyCode && (studyCode.code==="tutorat") && !studySessionHasNotOccured &&  student && 
          <div>
            <InfoSectionHeading subtitle={t("select-session-format")}/>
            <SelectPairingType removeHybrid value={sessionFormat} setValue={setSessionFormat}/>
          </div>}

          {/* Options */}
          {studyCode && student && <div>
            <InfoSectionHeading subtitle={t("select-options")}/>
            <Options
               value={options} 
               setValue={setOptions} 
               studyCode={studyCode &&studyCode.code} 
               studySessionIsTooShort={sessionLength && !studySessionHasNotOccured && studyCode.min_length_in_minutes>sessionLength}
               numTooShortStudySessionsWarnings={numTooShortStudySessionsWarnings}
               studySessionHasNotOccured={studySessionHasNotOccured}/>
          </div>}
         </div>
}

function StudySessionsListInSession({studySessions}){
  if (studySessions.length===0) return null
  return <div className="space-y-3">
              {studySessions.map(d=><StudySessionCell key={d.id} {...d}/>)}
            </div>
}

export function NewStudySession({team}){
  const [student, setStudent] = useState();
  const [subjects, setSubjects] = useState();
  const [date, setDate] = useState();
  const [studyCode, setStudyCode] = useState();
  const [sessionFormat, setSessionFormat] = useState();
  const [options, setOptions] = useState({});
  const [sessionLength, setSessionLength] = useState();
  const [studySessions, setStudySessions] = useState([]);
  const [studySessionRetaken, setStudySessionRetaken] = useState();
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [studySessionHasNotOccured, setStudySessionDidNotOccured] = useState(false);
  const [studySessionDoesCount, setStudySessionDoesCount] = useState(true);
  const [isRetake, setIsRetake] = useState(false);
  const [pairing, setPairing] = useState();
  
  const pairingParams = useMemo(()=>({ni: student && student.ni}), [student]);
  const [activePairings, pairingRequest] = useAPI(retrieveStudentActivePairing, pairingParams, {immediate: false});
  useEffect(()=>{
    if (student) pairingRequest.execute();
    setPairing();
  }, [student]);

  const onSuccess = useCallback((studySession)=>{
    setStudySessions(d=>[...d, studySession]);
    setShowSuccessView(true);
  }, [])

  const reset = useCallback(({resetStudent=true}={})=>{
    setShowSuccessView(false);
    if (resetStudent){
      setStudent();
    }
    setSubjects();
    setDate();
    setStudyCode();
    setSessionFormat();
    setStudySessionRetaken();
    setIsRetake({});
    setOptions({});
    setSessionLength();
    setStudySessionDidNotOccured(false);
    setStudySessionDoesCount(true);
  }, [])

  useEffect(()=>{
    if (!options) return;
    if (Object.keys(options).find(d=>d==="aide-aux-devoirs-trop-breve-apres-deux-avertissements-tutorat")){
      setStudySessionDoesCount(false);
    }
  }, [options])

  return <FlexNav breakdown="lg" className={"max-w-6xl mx-auto relative h-full"}>

            <FlexNav.Content breakdown="lg" className="py-6">
               {showSuccessView?
                <AfterCreationView reset={reset} studySessions={studySessions}/>
                :<CreationView
                student={student} 
                setStudent={setStudent} 
                team={team} 
                studyCode={studyCode} 
                setStudyCode={setStudyCode} 
                pairing={pairing} 
                setPairing={setPairing}
                activePairings={activePairings}
                pairingRequest={pairingRequest} 
                studySessionHasNotOccured={studySessionHasNotOccured} 
                setStudySessionDidNotOccured={setStudySessionDidNotOccured} 
                date={date} 
                setDate={setDate}
                sessionLength={sessionLength}
                setSessionLength={setSessionLength}
                subjects={subjects}
                setSubjects={setSubjects}
                sessionFormat={sessionFormat} 
                setSessionFormat={setSessionFormat} 
                studySessionRetaken={studySessionRetaken}
                setStudySessionDoesCount={setStudySessionDoesCount}
                setStudySessionRetaken={setStudySessionRetaken}
                isRetake={isRetake}
                setIsRetake={setIsRetake}
                options={options} 
                setOptions={setOptions}            
               />}
             </FlexNav.Content>
             <FlexNav.Bar breakdown="lg" className="order-last lg:border-l lg:pl-6 ">
              <div className="absolute sticky top-0 space-y-12 max-h-full pb-12">
                <SideNavigation student={student} 
                                studyCode={studyCode} 
                                date={date} 
                                options={options} 
                                subjects={subjects} 
                                pairing={pairing} 
                                activePairings={activePairings}
                                studySessionRetaken={studySessionRetaken}
                                isRetake={isRetake}
                                sessionFormat={sessionFormat}
                                studySessionDoesCount={studySessionDoesCount}
                                setStudySessionDoesCount={setStudySessionDoesCount}
                                onSuccess={onSuccess}
                                studySessionHasNotOccured={studySessionHasNotOccured}
                                sessionLength={sessionLength}/>
                {team && <TeamMemberListWithStudySessions team={team} onStudentClick={(s)=>{setStudent(s); if (showSuccessView){reset({resetStudent:false});}}} studySessions={studySessions}/>}
                <StudySessionsListInSession studySessions={studySessions}/>
                </div>
            </FlexNav.Bar>
            </FlexNav>
}
