
import React, { useCallback, useMemo, useState } from "react";

// Components
import { SectionHeading } from "components/core/typo";
import { Button } from "components/core/button";
import { Table } from "components/core/table";
import { Status, getStudentStatusFromNotes, STUDENT_STATUS_CODES, StatusLegend } from "components/follow-up/meetings/status";
import { Documents, DocumentsLegend } from "components/follow-up/meetings/documents";
import { Meetings, MeetingsCount } from "components/follow-up/meetings/meetings";
import { Skeleton } from "components/core/skeleton";
import { SlideOver } from "components/core/slide_over";
import { NoteExtendedViewQueryBased } from 'components/notes/card';
import { MeetingsSummary } from "components/follow-up/meetings/summary";
import { Pairings } from "components/follow-up/meetings/pairings";
import { ClassFailed } from "components/follow-up/meetings/class-failed";
import { Asa } from "components/follow-up/meetings/asa";
import { TeamContext } from "contexts/team";
import{ DocumentSlideOver } from "components/documents/slideover";
import { SocioAffectifs } from "components/follow-up/meetings/socio-affectifs";
import { Mifi } from "components/follow-up/meetings/mifi";
import { ActionsPlan, ActionsPlanStatus, ActionsPlanStatusLegend } from "components/follow-up/meetings/actions-plan";

// Utils
import classNames from "classnames";
import { nameToInitials } from "utils/format";

// API
import { searchDocumentGroupPeriods } from "api/document_groups";
import { retrieveNoteCode } from "api/notes";

// Hooks
import { useStudentsMeetings } from "contexts/student-meetings";
import { useTranslation } from "react-i18next";
import { useSearch } from "hooks/useSearch";
import { useQuery } from "hooks/useQuery";
import { useContext } from "hooks/useContext";
import { useAPI } from "hooks/useAPI";

export function StudentsList() {
    const { t } = useTranslation("common");
    const { students, setStudents, loading } = useStudentsMeetings();
    const { team } = useContext(TeamContext);
    const meetingCodeParams = useMemo(() => ({code: "rencontre-ea-entraineur"}), [])
    const [meetingCode, {loading:loadingMeetingCode}] = useAPI(retrieveNoteCode, meetingCodeParams);
    const params = useMemo(() => ({ team:team?.slug, period:team?.activePeriod?.slug, orderBy: "should_be_received_at" }), [team?.slug, team?.activePeriod?.slug])
    const validateParams = useCallback((params) => { return params.team && params.period }, [])
    const [documentsGroups, { loading: loadingGroups }] = useSearch(searchDocumentGroupPeriods, params, {camelize:true, validateParams})
    const { query, setQuery } = useQuery()
    const openNote = useMemo(() => { return query.has("noteId") }, [query])
    const openDocument = useMemo(() => { return query.has("documentId") }, [query])
    const handleDeleteNote = (noteId) => {
        setStudents(prev =>
            prev.map(student => {
                const updatedDocuments = student.documents?.map(document => ({
                    ...document,
                    notes: document.notes?.filter(note => note.id !== noteId) || []
                })) || student.documents;

                const updatedMeetings = student.meetings?.filter(meeting => meeting.id !== noteId) || [];
                
                const updatedSocialAffectifs = student.socioAffectifs?.filter(note => note.id !== noteId) || [];
                
                const updatedMifi = student.mifi?.filter(note => note.id !== noteId) || [];

                return {
                    ...student,
                    documents: updatedDocuments,
                    meetings: updatedMeetings,
                    socioAffectifs: updatedSocialAffectifs,
                    mifi: updatedMifi
                };
            })
        );
    };
    const deleteAllStatusNotesForDocument = (documentId) => {
        if (!documentId) return;
        setStudents(prev =>
            prev.map(student => {
                const updatedDocuments = student?.documents?.map(document => {
                    if (document.id === documentId) {
                        return {
                            ...document, 
                            notes: document.notes?.filter(note => !STUDENT_STATUS_CODES.map(status => status.code).includes(note.code)) || []
                        }
                    }
                    return document
                }) || student?.documents;
                
                return {
                    ...student,
                    documents: updatedDocuments
                };
            })
        );
    }
    
    const createOrUpdateDocumentNote = (documentId, note) => {
        if (!note || !documentId) return;
        setStudents(prev =>
            prev.map(student => {
                const updatedDocuments = student?.documents?.map(document => {
                    if (document.id === documentId) {
                        // check if note.id already exists, if so, update it
                        const updatedNotes = document.notes?.map(existingNote => {
                            if (existingNote.id === note.id) {
                                return note;
                            }
                            return existingNote;
                        }) || [];
                        // if note.id doesn't exist, add it
                        if (!updatedNotes.find(existingNote => existingNote.id === note.id)) {
                            updatedNotes.push(note);
                        }
                        return {
                            ...document,
                            notes: updatedNotes
                        }
                    }
                    return document
                }) || student?.documents;
                
                return {
                    ...student,
                    documents: updatedDocuments
                };
            })
        );
    };
    
    const handleDocumentDelete = ({id}) => {
        setStudents(prev => prev.map(student => {
            const updatedDocuments = student?.documents?.filter(document => document.id !== id) || student?.documents;
            return {
                ...student,
                documents: updatedDocuments
            };
        }));
        setQuery({});
    };
    
    const updateDocument = ({id, handedOverAt}) => {
        setStudents(prev => prev.map(student => {
            const updatedDocuments = student?.documents?.map(document => {
                if (document.id === id) {
                    return {
                        ...document,
                        handedOverAt
                    }
                }
                return document
            }) || student?.documents;
            
            return {
                ...student,
                documents: updatedDocuments
            };
        }));
    };
    
    const handleUpdateDocument = ({action, document, note, id, ...rest}) => {
        if (action && action == "status-set"){
            if (!document && !note) return;
            if (document && note) createOrUpdateDocumentNote(document, note);
            else if (document && !note) deleteAllStatusNotesForDocument(document);
        }
        else {
            updateDocument({id, ...rest});
        }
    };
                            
    const sortFunctions = {
        name: sortStudentsByName,
        status: sortByStatus,
        lastMeeting: sortByLastMeeting,
        nbMeetings: sortByMeetingCount,
        actionsPlan: sortByActionsPlan,
        classFailed: sortByClassFailed,
        tutoring: sortByPairings,
        mifi: sortByMifi,
        asa: sortByAsa,
        socioAffectifs: sortBySocioAffectifs,
        default: sortStudentsByName
    };
    const [sort, setSort] = useState("default")
    const sortedStudents = useMemo(() => {
        if (!students) return [];
        const sortFunction = sortFunctions[sort] || sortFunctions.default;
        return sortFunction(students);
    }, [students, sort]);
    const headers = useMemo(() => {
        const value = [
            {
                field: (d) => d,
                title: t("students"),
                className: "pl-2",
                active: sort === "name",
                itemClassName: "py-2",
                onHeaderClick: ()=> setSort(prev=> prev === "name" ? "default" : "name"),
                FormatComponent: (d) => <Student student={d} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("socio-affectifs"),
                className: "pl-2",
                active: sort === "socioAffectifs",
                itemClassName: "py-2",
                onHeaderClick: ()=> setSort(prev=> prev === "socioAffectifs" ? "default" : "socioAffectifs"),
                FormatComponent: (d) => <SocioAffectifs student={d} socioAffectifs={d?.socioAffectifs} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("status"),
                legend: <StatusLegend />,
                legendClassName: "!w-64",
                className: "pl-2",
                active: sort === "status",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "status" ? "default" : "status"),
                FormatComponent: (d) => <Status notes={d?.documents?.flatMap(document => document?.notes) || []} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("documents"),
                legend: <DocumentsLegend />,
                className: "pl-2",
                itemClassName: "py-2",
                FormatComponent: (d) => <Documents student={d} documents={d?.documents} documentsGroups={documentsGroups} loading={loadingGroups || loading} />
            },
            {
                field: (d) => d,
                title: t("meetings"),
                active: sort === "nbMeetings",
                className: "pl-2",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "nbMeetings" ? "default" : "nbMeetings"),
                FormatComponent: (d) => <MeetingsCount student={d} meetings={d?.meetings} meetingCode={meetingCode} loading={loading || loadingMeetingCode } />
            },
            {
                field: (d) => d,
                title: t("last-meeting"),
                active: sort === "lastMeeting",
                className: "pl-2",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "lastMeeting" ? "default" : "lastMeeting"),
                FormatComponent: (d) => <Meetings student={d} meetings={d?.meetings} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("planned-actions"),
                className: "pl-2",
                itemClassName: "py-0",
                active: sort === "actionsPlan",
                onHeaderClick: ()=> setSort(prev => prev === "actionsPlan" ? "default" : "actionsPlan"),
                FormatComponent: (d) => <ActionsPlan 
                    student={d} 
                    actionsPlan={d?.actionsPlan?.length > 0 && d?.actionsPlan[0]} 
                    loading={loading} 
                    setActionsPlan={(actionsPlan) => {
                        return setStudents(prev => prev.map(student => student.ni === d.ni ? {...student, actionsPlan: [actionsPlan]} : student))
                    }}
                />
            },
            {
                field: (d) => d,
                legend: <ActionsPlanStatusLegend />,
                legendClassName: "w-fit !max-w-[175px]",
                title: t("actions-status.title"),
                className: "pl-2",
                itemClassName: "py-0",
                FormatComponent: (d) => <ActionsPlanStatus 
                    actionsPlan={d?.actionsPlan?.length > 0 && d?.actionsPlan[0]} 
                    loading={loading} 
                    setActionsPlan={(actionsPlan) => {
                        return setStudents(prev => prev.map(student => student.ni === d.ni ? {...student, actionsPlan: [actionsPlan]} : student))
                    }}
                    />
            },
            {
                field: (d) => d,
                title: t("fails"),
                active: sort === "classFailed",
                className: "pl-2",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "classFailed" ? "default" : "classFailed"),
                FormatComponent: (d) => <ClassFailed student={d} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("tutoring"),   
                className: "pl-2",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "tutoring" ? "default" : "tutoring"),
                FormatComponent: (d) => <Pairings student={d} loading={loading} />
            },
            {
                field: (d) => d,
                title: t("mifi"),   
                className: "pl-2",
                active: sort === "mifi",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "mifi" ? "default" : "mifi"),
                FormatComponent: (d) => <Mifi student={d} mifi={d?.mifi} loading={loading} />
            },
            {
                field: (d) => d,
                title: "ASA",
                active: sort === "asa",
                className: "pl-2",
                itemClassName: "py-0",
                onHeaderClick: ()=> setSort(prev => prev === "asa" ? "default" : "asa"),
                FormatComponent: (d) => <Asa asa={d?.asa} loading={loading} />
            },
        ]

        return value
    }
    , [documentsGroups, loadingGroups, loading, sort])
    return <>
        <SectionHeading title={t("students")} ><MeetingsSummary/></SectionHeading>
        <div className="@container mb-10">
            <Table rowClassName="hover:bg-gray-100 group" data={loading ? [] : sortedStudents} headers={headers} indexingKey={d => d?.ni} />
            {
                loading && <div className="p-2">
                    <Skeleton className="w-full h-32" />
                </div>
            }
            {
                !loading && students?.length === 0 && <div className="p-2 flex justify-center text-gray-500">
                    {t("no-students")}
                </div>
            }
        </div>
        <SlideOver open={openNote} setOpen={()=>setQuery({})} size="xl3">
            <NoteExtendedViewQueryBased onDelete={()=>handleDeleteNote(query.get("noteId"))} />
        </SlideOver>
        <SlideOver open={openDocument} setOpen={()=>setQuery({})} size="xl3">
            <DocumentSlideOver onUpdate={handleUpdateDocument} onDelete={handleDocumentDelete} />
        </SlideOver>
    </>
}

function Student({ student, loading, className }) {
    const {t} = useTranslation("common");
    const studentName = student?.firstname && student?.lastname ? nameToInitials(student?.firstname, student?.lastname) : student?.name
    const willLeaveSoon = student?.endedUsingService;

    if (loading) return <>
        <Skeleton className={classNames("w-full h-5", className)} />
    </>
    return <>
        <Button href={`/students/${student?.ni}`} color={"hiddenLink"} className={classNames("w-fit mr-2", className)}>
        {studentName}
        </Button>
        {willLeaveSoon && <span className="px-2 py-0.5 whitespace-nowrap bg-yellow-200 text-yellow-800 text-xs  rounded-md">{t("student-will-left-service.title-dyna", {date: willLeaveSoon})}</span>}

    </>
}

export function sortStudentsByName(students, order = "asc") {
    if (!students) return [];
    return [...students].sort((a, b) => 
        order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
}

export function sortByStatus(students, order = "asc") {
    if (!students) return [];
    
    const statusOrder = {
        "fail": 1,
        "at-risk": 2,
        "success": 3
    };

    return [...students].sort((a, b) => {
        const statusA = getStudentStatusFromNotes(a?.documents?.flatMap(document => document?.notes) || [])?.status || "";
        const statusB = getStudentStatusFromNotes(b?.documents?.flatMap(document => document?.notes) || [])?.status || "";

        const orderA = statusOrder[statusA] || 4; // 4 for statuses not specified
        const orderB = statusOrder[statusB] || 4;

        if (orderA === orderB) {
            return order === "asc" ? statusA.localeCompare(statusB) : statusB.localeCompare(statusA);
        }

        return order === "asc" ? orderA - orderB : orderB - orderA;
    });
}

export function sortByLastMeeting(students, order = "asc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const lastMeetingA = a?.meetings?.sort((a, b) => new Date(b.openedAt) - new Date(a.openedAt))?.[0]?.openedAt || new Date(0);
        const lastMeetingB = b?.meetings?.sort((a, b) => new Date(b.openedAt) - new Date(a.openedAt))?.[0]?.openedAt || new Date(0);

        return order === "asc" ? new Date(lastMeetingA) - new Date(lastMeetingB) : new Date(lastMeetingB) - new Date(lastMeetingA);
    });
}

export function sortByMeetingCount(students, order = "asc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const meetingCountA = a?.meetings?.length || 0;
        const meetingCountB = b?.meetings?.length || 0;

        return order === "asc" ? meetingCountA - meetingCountB : meetingCountB - meetingCountA;
    });
}

export function sortByClassFailed(students, order = "desc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const classFailedA = a?.classFailed?.length || 0;
        const classFailedB = b?.classFailed?.length || 0;

        return order === "asc" ? classFailedA - classFailedB : classFailedB - classFailedA;
    });
}

export function sortByPairings(students, order = "desc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const pairingsA = a?.pairings?.length || 0;
        const pairingsB = b?.pairings?.length || 0;

        return order === "asc" ? pairingsA - pairingsB : pairingsB - pairingsA;
    });
}

export function sortByMifi(students, order = "desc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const mifiA = a?.mifi?.length || 0;
        const mifiB = b?.mifi?.length || 0;

        return order === "asc" ? mifiA - mifiB : mifiB - mifiA;
    });
}

export function sortBySocioAffectifs(students, order = "desc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const socioAffectifsA = a?.socioAffectifs?.length || 0;
        const socioAffectifsB = b?.socioAffectifs?.length || 0;

        return order === "asc" ? socioAffectifsA - socioAffectifsB : socioAffectifsB - socioAffectifsA;
    });
}

export function sortByAsa(students, order = "desc") {
    if (!students) return [];
    return [...students].sort((a, b) => {
        const asaA = a?.asa?.length || 0;
        const asaB = b?.asa?.length || 0;

        return order === "asc" ? asaA - asaB : asaB - asaA;
    });
}

export function sortByActionsPlan(students, order = "asc") {
    if (!students) return [];

    // Helper function to get the earliest upcoming display date
    const getUpcomingDisplayDate = (comments) => {
        if (!comments || comments.length === 0) return null;
        const futureComments = comments
            .filter(comment => comment.displayDate && comment.displayDate >= new Date().toLocaleDateString('en-CA'))
            .sort((a, b) => new Date(a.displayDate) - new Date(b.displayDate));
        return futureComments.length > 0 ? futureComments[0].displayDate : null;
    };

    return [...students].sort((a, b) => {
        const upcomingDateA = getUpcomingDisplayDate(a?.actionsPlan?.[0]?.comments);
        const upcomingDateB = getUpcomingDisplayDate(b?.actionsPlan?.[0]?.comments);
        const dateA = upcomingDateA ? new Date(upcomingDateA) : null;
        const dateB = upcomingDateB ? new Date(upcomingDateB) : null;

        // Ascending order
        if (order === "asc") {
            if (dateA && dateB) {
                return dateA - dateB; // Sort by the earliest date
            } else if (dateA && !dateB) {
                return -1; // dateA exists, dateB does not
            } else if (!dateA && dateB) {
                return 1; // dateB exists, dateA does not
            }
            return 0; // Both dates are null or invalid
        }

        // Descending order
        if (order === "desc") {
            if (dateA && dateB) {
                return dateB - dateA; // Sort by the latest date
            } else if (dateA && !dateB) {
                return -1; // dateA exists, dateB does not
            } else if (!dateA && dateB) {
                return 1; // dateB exists, dateA does not
            }
            return 0; // Both dates are null or invalid
        }
    });
}


