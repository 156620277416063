import React from 'react';
import classnames from 'classnames';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiInfo } from 'react-icons/fi';
import { SearchField } from 'components/tools/search_field'; 

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';
import { useState, useMemo} from 'react'

// API
import { searchNoteCode } from 'api/notes';
import { SelectNoteGroup } from './select_note_group';

export function ListeNoteCode({ value, setValue, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const [codes] = useSearch(searchNoteCode);

  return <ListSelection value={value}
                        setValue={setValue}
                        disabled={disabled}
                        values={codes}
                        label={t('note-code')}
                        Icon={FiInfo}
                        getKey={(d)=>d.code}
                        format={(d)=>d.name}
                        withClearSelection={!disabledClear}
                        {...props}
                        >
                  {(d)=><div className="flex items-center">
                    <div className="h-4 w-4 mr-2 rounded-md" style={{background: d.color}}/>
                    {/*<span className=" block font-medium ">{d.code}</span>*/}
                    <span className="ml-0 block truncate">{d.name}</span>
                  </div>}
              </ListSelection>
}

export function SearchNoteCode({label, value, className, setValue, group, special, disabled, color, size, targetWidth, showGroupFilter, placeholder}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const [groupFilter, setGroupFilter] = useState();
  const allParams = useMemo(()=>({special, group: group || groupFilter, ...params}), [params, groupFilter, special, group]);
  const [codes, {loading}] = useSearch(searchNoteCode, allParams);
  return <div className={classnames('flex items-start space-x-2', className)}>
            <SearchField values={codes}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      disabled={disabled}
                      color={color}
                      size={size}
                      label={label}
                      targetWidth={targetWidth}
                      placeholder={placeholder || t("note-code")}
                      indexingField={"code"}
                      formatSelectedValue={(d=>d.name)}
                      formatSearchResult={((d, {selected, active})=>
                        <div className="flex items-center ">
                          <div className="h-4 w-4 mr-2 rounded-md flex-shrink-0" style={{background: d.color}}/>
                          <div>
                            <span className={classnames('block', (active || selected))}>{d.name}</span>
                            <span className={classnames('text-sm first-letter:capitalize', !(active || selected) && "text-gray-500")}>{d.group}</span>
                          </div>
                        </div>)}
                     onParamsChange={setParams}/>
              {showGroupFilter && <SelectNoteGroup hideSelectedValue={true} value={groupFilter} setValue={setGroupFilter} />}
  </div>
}
