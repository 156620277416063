import React, { useCallback, useEffect, useMemo } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SingleLineField, ModifiableSelectField, Label } from 'components/tools/modifiable_fields';
import { SelectDate } from 'components/tools/select_date';
import { CheckBox } from 'components/tools/checkbox';
import { Info } from 'components/core/info';
import { FiLock } from 'react-icons/fi';
import { Error, TextErrorStructured, InfoSectionHeading, RawTextSuccess, RawTextWarning } from 'components/core/typo'
import { Button } from 'components/core/button';
import { SelectPeriods } from 'components/tools/select_period';
import { SelectSettings } from 'components/end-year-assistant/coachs/settings';
import toast from 'react-hot-toast';

// Utils
import classnames from 'classnames';
import { locale, dateParse } from 'utils/locale';
import { getFullCurrentPeriod } from 'utils/period';

// Contexts
import { TutorContext } from 'contexts/tutor';
import { TutorsContext } from 'contexts/tutors';

// Hooks
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { useAPI } from 'hooks/useAPI';

// API
import { updateTutor, deleteTutor, openTutor, closeTutor } from 'api/tutors';
import { FieldsSection } from 'components/core/fields-section';
import { SelectFacebookStatus } from 'components/tools/select_facebook_status';
import { SelectFacebookGroupStatus } from 'components/tools/select_facebook_group_status';

function DeleteButton({ ni }) {
    const { t } = useTranslation('common');
    const [lock, setLock] = useState(true);
    const navigate = useNavigate();
    const handleDelete = useCallback(() => {
        navigate(`/tutors`);
        toast.success(t("delete-tutor.success"), { duration: 3000 })
    }, [])
    const [, { execute, loading, error }] = useAPI(deleteTutor,
        { ni },
        { onResult: handleDelete, immediate: false })


    return <div className="relative">
        {lock && <div className="absolute z-30 bg-gray-100  rounded-md flex items-center justify-center w-full h-full text-center">
            <div>
                <h3 className="pb-3 text-lg text-gray-600">{t("delete-tutor.title")}</h3>
                <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock className='mr-2' /> {t("delete-tutor.unlock-section")}</Button>
            </div>
        </div>}
        <div className={classnames(" rounded-lg p-3 w-full space-y-3", lock ? "" : "border-2 border-red-500")}>
            <div>
                <h3 className="font-medium text-lg text-red-600">{t("delete-tutor.title")}</h3>
                <p className="text-gray-500">{t("delete-tutor.description")}</p>
            </div>
            <Button disabled={lock} loading={loading} onClick={execute} color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-tutor.button")}</Button>
            {error ? <Error.Text {...error} /> : null}
        </div>
    </div>
}



export function TutorGeneralSettings() {
    const { tutor, setTutor } = useContext(TutorContext);
    const modify = true;
    const { t } = useTranslation('common');
    const ni = tutor.ni;
    const [, { error, execute }] = useAPI(updateTutor, {}, { immediate: false })


    const tutorShouldBeReady = useMemo(() => {
        if (!tutor || !tutor.name) return false;
        return tutor.code_ethique_document_received && tutor.contrat_engagement_document_received && tutor.membership_document_received && tutor.cyber_security_policy_document_received && tutor.antecedent_judiciaire_document_received && tutor.formation_completed && !tutor.ready;
    }, [tutor]);

    const tutorShouldNotBeReady = useMemo(() => {
        if (!tutor || !tutor.name) return false;
        return !tutor.code_ethique_document_received || !tutor.contrat_engagement_document_received || !tutor.membership_document_received || !tutor.cyber_security_policy_document_received || !tutor.antecedent_judiciaire_document_received || !tutor.formation_completed;
    }, [tutor]);

    if (!tutor.name) {
        return <Skeleton className="w-full h-36" />
    }
    return <div className={classnames("")}>
        <h1 className="info-section">{t('personal-info')}</h1>
        {error ? <Error.Text {...error} /> : null}
        <FieldsSection title={t("tutor-settings.contact.title")} name="ins2">
            <Info.Container modify={modify}>
                <SingleLineField
                    label={modify && t("firstname")}
                    modify={modify}
                    value={tutor.firstname}
                    setValue={(firstname) => { execute({ ni, firstname }); setTutor(d => ({ ...d, firstname })) }}>
                    {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`} />}
                </SingleLineField>
                <SingleLineField
                    label={modify && t("lastname")}
                    modify={modify}
                    value={tutor.lastname}
                    setValue={(lastname) => { execute({ ni, lastname }); setTutor(d => ({ ...d, lastname })) }}>
                    {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`} />}
                </SingleLineField>
                <SingleLineField
                    label={modify && t("email")}
                    modify={modify}
                    value={tutor.email}
                    setValue={(email) => { execute({ ni, email }); setTutor(d => ({ ...d, email })) }}>
                    {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`} />}
                </SingleLineField>
                <SingleLineField
                    label={modify && t("phone")}
                    modify={modify}
                    value={tutor.phone}
                    setValue={(phone) => { execute({ ni, phone }); setTutor(d => ({ ...d, phone })) }}>
                    {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`} />}
                </SingleLineField>
                <div className='flex-1'>
                    <SingleLineField
                        label={modify && t("facebook_account")}
                        modify={modify}
                        value={tutor.facebook_account}
                        setValue={(facebook_account) => { execute({ ni, facebookAccount: facebook_account }); setTutor(d => ({ ...d, facebook_account })) }}>
                    </SingleLineField>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                    <div className=''>
                        <Label>{t("facebook-status")}</Label>
                        <SelectFacebookStatus className="w-full" value={tutor.facebook_friend_status} setValue={(v) => { execute({ ni, facebookFriendStatus: v && v.slug }); setTutor(d => ({ ...d, facebook_friend_status: v })) }} />
                    </div>
                    <div className=''>
                        <Label>{t("facebook-group-status")}</Label>
                        <SelectFacebookGroupStatus className="w-full" value={tutor.facebook_group} setValue={(v) => { execute({ ni, facebookGroup: v && v.slug }); setTutor(d => ({ ...d, facebook_group: v })) }} />
                    </div>
                </div>
            </Info.Container>
        </FieldsSection>

        <FieldsSection title={t("tutor-settings.inscription.title")} name="ins1">

            <Info.Container modify={true} className="" >
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("code-ethique-confirmed")}
                    showXIfFalse={true}
                    showCheckIfTrue={true}
                    modify={true}
                    blurred={tutor.code_ethique_document_received || false}
                    value={tutor.code_ethique_document_received || false}
                    subLabel={tutor.code_ethique_document_received && t("receive-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.code_ethique_document_received)) })}
                    setValue={(codeEthiqueDocumentReceived) => { execute({ ni, codeEthiqueDocumentReceived }); setTutor(d => ({ ...d, code_ethique_document_received: codeEthiqueDocumentReceived && new Date() })) }} />
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("contrat-engagement-document-received")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    blurred={tutor.contrat_engagement_document_received || false}
                    value={tutor.contrat_engagement_document_received || false}
                    subLabel={tutor.contrat_engagement_document_received && t("receive-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.contrat_engagement_document_received)) })}
                    setValue={(contratEngagementDocumentReceived) => { execute({ ni, contratEngagementDocumentReceived }); setTutor(d => ({ ...d, contrat_engagement_document_received: contratEngagementDocumentReceived && new Date() })) }} />
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("cyber-security-policy-document-received")}
                    modify={true}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    blurred={tutor.cyber_security_policy_document_received || false}
                    value={tutor.cyber_security_policy_document_received || false}
                    subLabel={tutor.cyber_security_policy_document_received && t("receive-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.cyber_security_policy_document_received)) })}
                    setValue={(cyberSecurityPolicyDocumentReceived) => { execute({ ni, cyberSecurityPolicyDocumentReceived }); setTutor(d => ({ ...d, cyber_security_policy_document_received: cyberSecurityPolicyDocumentReceived && new Date() })) }} />

                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("membership-document-received")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    alwaysFlex={true}
                    modify={true}
                    subLabel={tutor.membership_document_received && t("receive-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.membership_document_received)) })}
                    blurred={tutor.membership_document_received || false}
                    value={tutor.membership_document_received || false}
                    setValue={(membershipDocumentReceived) => { execute({ ni, membershipDocumentReceived }); setTutor(d => ({ ...d, membership_document_received: membershipDocumentReceived && new Date() })) }} />

                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("antecedent-judiciaire-document-received")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    subLabel={tutor.antecedent_judiciaire_document_received && t("receive-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.antecedent_judiciaire_document_received)) })}
                    modify={true}
                    blurred={tutor.antecedent_judiciaire_document_received || false}
                    value={tutor.antecedent_judiciaire_document_received || false}
                    setValue={(antecedentJudiciaireDocumentReceived) => { execute({ ni, antecedentJudiciaireDocumentReceived }); setTutor(d => ({ ...d, antecedent_judiciaire_document_received: antecedentJudiciaireDocumentReceived && new Date() })) }} />

                {/* Add  antecedent_judiciaire_document_conform: Optional[dt.datetime] = None
    antecedent_judiciaire_document_sent: Optional[dt.datetime] = None
    antecedent_judiciaire_document_validated: Optional[dt.datetime] = None
 */}
                {/* antecedent_judiciaire_document_conform */}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("antecedent-judiciaire-document-conform")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    blurred={tutor.antecedent_judiciaire_document_conform || false}
                    value={tutor.antecedent_judiciaire_document_conform || false}
                    subLabel={tutor.antecedent_judiciaire_document_conform && t("conform-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.antecedent_judiciaire_document_conform)) })}
                    setValue={(antecedentJudiciaireDocumentConform) => { execute({ ni, antecedentJudiciaireDocumentConform }); setTutor(d => ({ ...d, antecedent_judiciaire_document_conform: antecedentJudiciaireDocumentConform && new Date() })) }} />
                {/* antecedent_judiciaire_document_sent */}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("antecedent-judiciaire-document-sent")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    blurred={tutor.antecedent_judiciaire_document_sent || false}
                    value={tutor.antecedent_judiciaire_document_sent || false}
                    subLabel={tutor.antecedent_judiciaire_document_sent && t("sent-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.antecedent_judiciaire_document_sent)) })}
                    setValue={(antecedentJudiciaireDocumentSent) => { execute({ ni, antecedentJudiciaireDocumentSent }); setTutor(d => ({ ...d, antecedent_judiciaire_document_sent: antecedentJudiciaireDocumentSent && new Date() })) }} />
                {/* antecedent_judiciaire_document_validated */}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("antecedent-judiciaire-document-validated")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    blurred={tutor.antecedent_judiciaire_document_validated || false}
                    value={tutor.antecedent_judiciaire_document_validated || false}
                    subLabel={tutor.antecedent_judiciaire_document_validated && t("validated-on-date", { date: locale.format("%d %B %Y")(new Date(tutor.antecedent_judiciaire_document_validated)) })}
                    setValue={(antecedentJudiciaireDocumentValidated) => { execute({ ni, antecedentJudiciaireDocumentValidated }); setTutor(d => ({ ...d, antecedent_judiciaire_document_validated: antecedentJudiciaireDocumentValidated && new Date() })) }} />
                {/* Same as formation but for call_made */}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("call-made")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    marker="date"
                    blurred={tutor.call_made || false}
                    canRemoveSelection={true}
                    value={tutor.call_made ? dateParse(tutor.call_made) : null}
                    subLabel={tutor.call_made && t("call-made-on-date", { date: locale.format("%d %B %Y")(dateParse(tutor.call_made)) })}
                    setValue={(callMade) => { execute({ ni, callMade: callMade ? locale.format("%x")(callMade) : null }); setTutor(d => ({ ...d, call_made: callMade && locale.format("%x")(callMade) })) }} />


                {/* Formation */}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("formation-completed")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    marker="date"
                    blurred={tutor.formation_completed || false}
                    value={tutor.formation_completed ? dateParse(tutor.formation_completed) : null}
                    subLabel={tutor.formation_completed && t("formation-completed-on-date", { date: locale.format("%d %B %Y")(dateParse(tutor.formation_completed)) })}
                    setValue={(formationCompleted) => { execute({ ni, formationCompleted: formationCompleted ? locale.format("%x")(formationCompleted) : null }); setTutor(d => ({ ...d, formation_completed: formationCompleted && locale.format("%x")(formationCompleted) })) }} />


                {/* Ready */}

                {tutorShouldBeReady && <RawTextSuccess>
                    {t("tutor-ready.should-be-ready")}
                </RawTextSuccess>}

                {tutorShouldNotBeReady && <RawTextWarning>
                    {t("tutor-ready.should-not-be-ready")}
                </RawTextWarning>}
                <ModifiableSelectField
                    Field={CheckBox}
                    label={t("tutor-ready.title")}
                    showCheckIfTrue={true}
                    showXIfFalse={true}
                    modify={true}
                    noBorder={true}
                    blurred={tutor.ready || false}
                    value={tutor.ready || false}
                    subLabel={tutor.ready ? t("tutor-ready.is-ready") : t("tutor-ready.is-not-ready")}
                    setValue={(ready) => { execute({ ni, ready }); setTutor(d => ({ ...d, ready })) }} />
            </Info.Container>
        </FieldsSection>
        <div className='mt-12'>
            <ModifiableSelectField
                Field={SelectDate}
                label={modify && t("active-since")}
                orientation={"right"}
                modify={modify}
                value={tutor.active_since && new Date(dateParse(tutor.active_since))}
                setValue={(activeSince) => execute({ ni, activeSince: activeSince || null })} >
                {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
                    noValueLabel={`${t("none", { context: 'female' })} ${t("active-since").toLowerCase()}`}
                    label={t("active-since")}
                />}
            </ModifiableSelectField>
            <ModifiableSelectField
                Field={SelectDate}
                label={modify && t("retired-of-service")}
                modify={false}
                orientation={"right"}
                value={tutor.retired_of_service_since && new Date(dateParse(tutor.retired_of_service_since))}
                setValue={(retiredOfService) => { execute({ ni, retiredOfServiceSince: retiredOfService || null }); setTutor(d => ({ ...d, retired_of_service_since: retiredOfService })) }} >
                {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
                    noValueLabel={`${t("none", { context: 'female' })} ${t("retired-of-service").toLowerCase()}`}
                    label={t("retired-of-service")}
                />}
            </ModifiableSelectField>

            {tutor.retired_of_service_since && <SingleLineField
                label={modify && t("reason-for-end-service")}
                modify={modify}
                multiLine
                value={tutor.reason_for_end_of_service}
                setAPI={{ method: updateTutor, extraParams: { ni }, name: 'reasonForEndOfService' }}
                setValue={(val) => { setTutor(d => ({ ...d, reason_for_end_of_service: val })) }}>
                {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("reason-for-end-service").toLowerCase()}`} />}
            </SingleLineField>}

        </div>

        <div className="pt-6">
            <SingleLineField
                label={modify && t("comments")}
                modify={modify}
                value={tutor.comments}
                multiLine={true}
                setValue={(comments) => execute({ ni, comments })}>
                {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("comments").toLowerCase()}`} />}
            </SingleLineField>
        </div>

        <Info.Container modify={true} className="mt-12">
            <DeleteButton ni={tutor.ni} />
        </Info.Container>


    </div>
}

export function TutorCloseSettings() {
    const { t } = useTranslation('common');
    const { updateTutor: setUpdatedTutor } = useContext(TutorsContext);
    const { tutor, setTutor } = useContext(TutorContext);
    const [settings, setSettings] = useState({ ni: tutor.ni, reason: t("settings-tutors.close.reason-of-end-default"), endedAt: new Date() });
    const [result, { execute, loading, error }] = useAPI(closeTutor,
        settings, { onResult: (value) => { setTutor(value); (setUpdatedTutor && setUpdatedTutor(value)) }, immediate: false })
    const tutorIsClosed = tutor && tutor.retired_of_service_since && new Date(tutor.retired_of_service_since) < new Date();

    useEffect(() => {
        if (result) {
            toast.success(t("settings-tutors.close.success"), { duration: 3000 })
        }
    }, [result])

    return <div>
        {tutorIsClosed == true && <TextErrorStructured title={t("settings-tutors.close.already-closed")} />}
        <InfoSectionHeading mediumTitle={t("settings-tutors.close.title")} longDescription={t("settings-tutors.close.description")} />

        <SelectSettings value={settings} setValue={setSettings} />
        <InfoSectionHeading className={"mt-4"} mediumTitle={t("settings-tutors.close.summary.title")} longDescription={t("settings-tutors.close.summary.description")} />
        <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500")}>
            <div>
                <h3 className="font-medium text-red-600">{t("settings-tutors.close.title")}</h3>
                <p className="text-gray-500">{t("settings-tutors.close.summary.warning")}</p>
            </div>
            <Button loading={loading} onClick={execute} disabled={!settings.endedAt || !settings.reason || tutorIsClosed} block color="deleteContrast" size="lg">{tutorIsClosed ? t("settings-tutors.close.already-closed-short") : t("settings-tutors.close.button")}</Button>
            {error ? <Error.Text {...error} /> : null}
        </div>
    </div>

}

export function TutorOpenSettings() {
    const { t } = useTranslation('common');
    const { updateTutor: setUpdatedTutor } = useContext(TutorsContext);
    const { tutor, setTutor } = useContext(TutorContext);
    const [period, setPeriod] = useState(getFullCurrentPeriod());
    const settings = useMemo(() => ({ ni: tutor.ni, period }), [tutor, period]);
    const [result, { execute, loading, error }] = useAPI(openTutor,
        settings, { onResult: (value) => { setTutor(value); (setUpdatedTutor && setUpdatedTutor(value)) }, immediate: false })
    const modify = true;
    const tutorIsClosed = tutor && tutor.retired_of_service_since;

    useEffect(() => {
        if (result) {
            toast.success(t("settings-tutors.open.success"), { duration: 3000 })
        }
    }, [result])

    return <div>
        {!tutorIsClosed && <TextErrorStructured title={t("settings-tutors.open.already-opened")} />}
        <InfoSectionHeading mediumTitle={t("settings-tutors.open.title")} longDescription={t("settings-tutors.open.description")} />
        <ModifiableSelectField Field={SelectPeriods} label={modify && t("settings-tutors.open.periods")} modify={modify} setValue={setPeriod} value={period} />
        <InfoSectionHeading className={"mt-4"} mediumTitle={t("settings-tutors.open.summary.title")} longDescription={t("settings-tutors.open.summary.description")} />
        <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500")}>
            <div>
                <h3 className="font-medium text-red-600">{t("settings-tutors.open.title")}</h3>
                <p className="text-gray-500">{t("settings-tutors.open.summary.warning")}</p>
            </div>
            <Button loading={loading} onClick={execute} disabled={!tutorIsClosed || !period} block color="deleteContrast" size="lg">{!tutorIsClosed ? t("settings-tutors.open.already-opened-short") : t("settings-tutors.open.button")}</Button>
            {error ? <Error.Text {...error} /> : null}
        </div>
    </div>
}