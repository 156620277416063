import {  cancelPlayTime, getEventConversation, transmitPlayTime } from 'api/events';
import { retrieveTeam } from 'api/teams';
import classNames from 'classnames';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { Modal } from 'components/core/modal';
import { Skeleton } from 'components/core/skeleton';
import { useAPI } from 'hooks/useAPI';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowRight, FiCheck, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { dateParse, parseWithTimeZone, locale } from 'utils/locale';

function ConversationList({event}){
    const {t} = useTranslation();
    const params = useMemo(() => ({id: event && event.id}), [event]);
  const [convs, {loading}] = useAPI(getEventConversation, params);
  if (loading) return <Skeleton className='h-8'/>
  return <div>
    <h3 className='font-medium'>{t("seen-conv")}</h3>
    {convs && convs.length===0 && <EmptyState title={t("convs.empty.title")} description={t("convs.empty.description")}/>}
    {(convs||[]).map((c) =>
     <div key={c.id} className='flex items-center justify-between hover:bg-gray-50'>
      <div>
        <Link to={`/portal-access/coachs?ni=${c.portal_user && c.portal_user.ni}`} className='hover:underline'>{c.portal_user && c.portal_user.name }</Link>
        
      </div>
      {c.has_unseen_messages?
        <p  className='flex items-center gap-2 text-red-500'><FiX/> {t("unseen")}</p>:
        <p className='flex items-center gap-2 text-green-500'><FiCheck/> {t('seen', {date: locale.format("%d %b à %H:%M")(parseWithTimeZone(c.last_seen))})}</p>

      }
      </div>
    )}
  </div>
}
export function TransmissionStatusWithModification({event, setEvent}){
    const {t} = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [team, {execute: fetchTeam, loading}] = useAPI(retrieveTeam, {slug: event && event.team && event.team.slug}, {immediate: false});
    const [transmitted, {execute: transmit, loading: transmitting}] = useAPI(transmitPlayTime, {id: event.id}, {immediate: false, onResult: () => setEvent(({...event, transmitted: (new Date()).toISOString().slice(0, 10)}))});

    const coachNis = [team && team.coach && team.coach.ni, 
                  team && team.assistant_coach && team.assistant_coach.ni,
                  team && team.sport_manager && team.sport_manager.ni
                ].filter(Boolean);

    useEffect(() => {
      if (!team && modalOpen && !loading){
       fetchTeam();
      }
    }, [team, modalOpen, loading])

    // useEffect(() => {
    //   if (!modalOpen){
    //     setTransmitted(null);
    //   }
    // }, [modalOpen])

    return <>
            {event.transmitted?
            <div className={classNames('border p-3 rounded-md space-y-3 ', event.transmitted && "border-2 border-blue-500")}>
            <div className='flex items-center  justify-between gap-3 pr-2 py-2 '>
              <div>
                <h2 className='font-medium'>{t("play-time-transmitted")}</h2>
                <p className='text-gray-500'>{event.transmitted? t("play-time-transmitted-on-date", 
                  { date: locale.format("%d %B %Y")(dateParse(event.transmitted))}): t("play-time-transmitted-not-on-date") }</p>
                <Button color="delete" size="inline" onClick={()=>{cancelPlayTime({id: event.id}); setEvent({...event, transmitted: null}) }}>{t("play-time-cancel-transmit")}</Button>
            </div>
            <FiCheck className='text-green-500 !h-8 !w-8'/>
            </div>
                <ConversationList event={event}/>
                </div>
                :
            <div className='flex items-center justify-between gap-2 mt-2'>
                <h2>{t("play-time-not-transmitted")}</h2>
                <Button color="gray" size="md" onClick={() => setModalOpen(true)}>{t("transmit-play-time.button-modal")} <FiArrowRight className='ml-2'/></Button>
            </div>
            
        }
         <Modal open={modalOpen} setOpen={setModalOpen}>
          <Modal.Title>
            {t("transmit-play-time.title")}
          </Modal.Title>
            {!transmitted?
            <>
           <Modal.Body>
          <div className='space-y-3'>
                <p className='text-gray-500'>{t("transmit-play-time.description1")}</p>
              
                <p className='text-gray-500'>{t("transmit-play-time.description3")}</p>
              </div>
              <div className='space-y-2'>
              {loading && <Skeleton className='h-8'/>}
              {team && !team.coach && !team.assistant_coach && !team.sport_manager && <p>{t("transmit-play-time.no-coach")}</p>}
              {team && team.coach &&
              <Link className='hover:underline block mt-3' to={`/coachs/${team.coach.ni}`}><p>{`${team.coach.name} (${team.coach.email})`}</p></Link>}
            {team && team.assistant_coach &&
              <Link className='hover:underline block ' to={`/coachs/${team.assistant_coach.ni}`}><p>{`${team.assistant_coach.name} (${team.assistant_coach.email})`}</p></Link>}
            {team && team.sport_manager &&
              <Link className='hover:underline block ' to={`/coachs/${team.sport_manager.ni}`}><p>{`${team.sport_manager.name} (${team.sport_manager.email})`}</p></Link>}
              </div>
              </Modal.Body>

              <Modal.Body>
              <Button color="active" size="md" 
              disabled={coachNis.length===0}
              loading={transmitting} 
              block onClick={() => transmit({coachNis})}>{t("transmit-play-time.button")}</Button>
                {coachNis.length===0 && <div className='text-red-500'>{t("transmit-play-time.no-coach")}</div>}
              <div className='text-yellow-800 bg-yellow-100 p-3 rounded-md mt-4'>
                  <p className='font-bold ' >{t("transmit-play-time.description2a")}</p>
                  <p >{t("transmit-play-time.description2")}</p>
                  </div>
              </Modal.Body>
              </>
              :
          <div className='space-y-3'>
            <p>{t("transmit-play-time.success")}</p>
            <Button color="active" size="md" onClick={() => setModalOpen(false)}>{t("transmit-play-time.close")}</Button>
          </div>
          }
          </Modal>
            </>

}