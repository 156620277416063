import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';

// Utils
import classnames from 'classnames';
// import { locale } from 'utils/locale';
// import { format } from 'd3-format';

// Hooks
import { useTranslation } from 'react-i18next';

export function TeamInfo({ team }){
  const { t } = useTranslation('common');
  if (!team.name){
    return <Skeleton.List numElements={5} className="space-y-3" itemClassName="h-36"/>
  }
  return <div className={classnames("space-y-6")}> 
            <Info.Container label={"Info"} >
                <Info.Field value={team.name} noValueLabel={`${t("none")} ${t("name").toLowerCase()}`}/>
                <Info.Field value={team.active_period && team.active_period.name} label={t("active-year")} noValueLabel={`${t("none")} ${t("active-year").toLowerCase()}`}/>
                <Info.Field value={team.rseq_url} noValueLabel={`${t("none")} ${t("rseq")}`}/>
            </Info.Container>

            <Info.Container label={t("coach")} >
                <Info.Field value={team.coach && team.coach.name} label={t("coach")} noValueLabel={`${t("none")} ${t("coach").toLowerCase()}`}/>
                <Info.Field value={team.assistant_coach && team.assistant_coach.name}  label={t("assistant-coach")} noValueLabel={`${t("none")} ${t("assistant-coach").toLowerCase()}`}/>
                <Info.Field value={team.sport_manager && team.sport_manager.name}  label={t("sport-manager")} noValueLabel={`${t("none")} ${t("sport-manager").toLowerCase()}`}/>
            </Info.Container>

            <Info.Container  >
              <Info.Field value={team.school  && team.school.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("school").toLowerCase()}`}
                                        label={t("school")}
                                        />
              <Info.Field value={team.sport  && team.sport.name} 
                                        noValueLabel={`${t("none", {context: 'female'})} ${t("school-year").toLowerCase()}`}
                                        label={t("sport")}
                                        />     
            </Info.Container>
            <Button color="black" size="md" block href={`/teams/${team.slug}/settings/`}>{t("modify")}</Button>
         </div>
}

